import moment from 'moment';
import {
  DateLabel,
  Item,
  TransactionAmount,
  TransactionDetail,
  TransactionLabel,
} from 'assets/styles/Transaction.styles';
import { Transaction } from 'core/Types';

const TransactionItem = (props: Transaction) => {

  const getAmount = (): number => {
    if (props.action === "send")
      return (-1 * props.amount);
    else
      return props.amount;
  }

  const getLabel = (): string => {
    if (props.action === "send")
      return `Sent to ${props.counterparty}`;
    else if (props.action === "redeem")
      return "Redeemed";
    else if (props.action === "receive")
      return `Received from ${props.counterparty}`;
    return "";
  }

  return (
    <Item>
      <DateLabel>{moment.unix(props.timestamp).format("DD MMMM YYYY")}</DateLabel>
      <TransactionDetail>
        <TransactionLabel>{getLabel()}</TransactionLabel>
        <TransactionAmount>{getAmount()}</TransactionAmount>
      </TransactionDetail>
    </Item>
  );
};

export default TransactionItem;


