const Colors = {
    TITLE_LABEL_COLOR: "#666666",
    DEFAULT_TEXT_COLOR: "#333333",
    PLACEHOLDER_TEXT_COLOR: "#BDBDBD",
    BLACK: "#000000",
    WHITE: "#ffffff",
    LIGHT_GRAY: "#BBBBBB",
    ACTIVE_BLUE: "#1B71FA",
    INACTIVE_BLUE: "#A2BDFF",
    TITLE_COLOR: "#08224A",
    DATE_COLOR: "#999999",
}

export default Colors;