import { useEffect } from 'react';

import { Label, ScreenTitleLabel } from 'assets/styles/App.styles';
import { Wrapper } from 'assets/styles/ScanOrCode.styles';

import { BackButton, QrReader } from 'components';
import { Constants } from 'utils/Constants';

import Utils from 'utils/Utils';
import UserUtils from 'utils/UserUtils';

const ScanQrCodePage = (props: any) => {

  // Redirect to login if there is no session item for key
  useEffect(() => {
    if (Utils.isNullOrEmpty(UserUtils.getKey())) {
      props.history.replace(Utils.appendTestingParams("/login/"));
    }
  }, [props.history]);

  const handleScan = (data: string | null) => {
    if (!data) { return }
    console.log('QR data', data);
    sessionStorage.setItem(Constants.SEND_ADDRESS, data);
    props.history.goBack();
  }

  return (
    <Wrapper>
      <BackButton to={"/send"} />
      <ScreenTitleLabel>Scan Address</ScreenTitleLabel>

      <Label>Place the QR code of the recipient account in the middle</Label>
      <br />
      <QrReader onError={console.error} onScan={handleScan} />

    </Wrapper>
  );

}

export default ScanQrCodePage;
