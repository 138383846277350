import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import {
  ListItem,
  RedeemWrapper,
  UnorderedListItem
} from "assets/styles/RedeemPage.styles";

import {
  Label,
  ScreenTitleLabel,
  Wrapper,
  RedeemConfirmationContentWrapper,
  RedeemConfirmationMessage,
  ConfirmationButtonWrapper
} from "assets/styles/App.styles";

import { DebugPageLink } from "assets/styles/MediaDebugger.styles";

import { Button, BackButton, Loader, QrReader } from 'components';

import ApiManager from 'core/ApiManager';

import { RedeemRequest, TransactionStatus } from "core/Types";

import RedeemUtils from 'utils/RedeemUtils';

import Utils from 'utils/Utils';
import UserUtils from 'utils/UserUtils';


const RedeemPage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [balance, setBalance] = useState(0.0);
  const [code, setCode] = useState("");
  const [redeemPayload, setRedeemPayload] = useState<RedeemRequest | null>(null);

  let checkStatusInterval: NodeJS.Timeout | null = null;

  // Redirect to login if there is no session item for key
  useEffect(() => {
    if (Utils.isNullOrEmpty(UserUtils.getKey())) {
      props.history.replace(Utils.appendTestingParams("/login/"));
    }
  }, [props.history]);

  const handleModalClose = () => {
    setShowConfirmation(false);
  }

  const handleScan = (data: number[] | null) => {
    if (!data) { return }
    const redeemRequestPayload = RedeemUtils.getApiParams(data);
    redeemRequestPayload && processRedeemRequest(redeemRequestPayload);
  }

  const processRedeemRequest = async (payload: RedeemRequest) => {

    setLoading(true);
    let bankNoteDetails = await ApiManager.getBankNoteDetails(payload.banknote_address);
    setLoading(false);

    if (bankNoteDetails.data && bankNoteDetails.data.error) {
      alert(bankNoteDetails.data.error);
      return;
    } else if (bankNoteDetails.data && bankNoteDetails.data.balance === 0) {
      alert("The bank note has already been redeemed.");
      return;
    } else {
      setRedeemPayload(payload);
      setBalance(bankNoteDetails.data.balance);
      setCode(bankNoteDetails.data.code);

      setShowConfirmation(true);
    }
  }

  const signTransaction = async () => {
    setShowConfirmation(false);

    if (redeemPayload) {
      setLoading(true);
      let redeemResponse = await ApiManager.redeem(redeemPayload);
      if (redeemResponse.tx_hash) {
        checkStatusInterval = setInterval(() => {
          getTransactionStatus(`${redeemResponse.tx_hash}`)
        }, 1000);
      } else {
        alert("An error occurred while redeeming the amount, please try again.")
        setLoading(false);
      }
    }
  }

  const cancelTransaction = () => {
    setShowConfirmation(false);
  }

  const getTransactionStatus = async (txHash: string) => {
    if (!loading) {
      setLoading(true);
    }
    let status = await ApiManager.getTransactionStatus(txHash);
    if (status === TransactionStatus.PROCESSED) {
      setLoading(false);
      if (checkStatusInterval) {
        clearInterval(checkStatusInterval);
      }
      props.history.goBack();
    }
  }

  return (
    <Wrapper>
      <BackButton />
      <ScreenTitleLabel>Redeem</ScreenTitleLabel>

      <RedeemWrapper>
        <Label>To redeem the tokens:</Label>
        <UnorderedListItem>
          <ListItem>Scrap the key sign on the banknote</ListItem>
          <ListItem>Place the revealed code in the middle of the camera view</ListItem>
        </UnorderedListItem>
        <QrReader binaryData onError={console.error} onScan={handleScan} />

        <DebugPageLink onClick={() => { props.history.push(Utils.appendTestingParams("/debug/")); }}>
          v1.0.6
        </DebugPageLink>

      </RedeemWrapper>

      {loading && <Loader />}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirmation} onHide={handleModalClose}>
        <Modal.Body>
          <RedeemConfirmationContentWrapper>
            <RedeemConfirmationMessage>Are you sure you want to redeem <strong>{balance} {code}</strong> to your account?</RedeemConfirmationMessage>
          </RedeemConfirmationContentWrapper>
          <ConfirmationButtonWrapper>
            <Button onClick={signTransaction}>Confirm</Button> <br />
            <Button hollow onClick={cancelTransaction}>Cancel</Button>
          </ConfirmationButtonWrapper>
        </Modal.Body>
      </Modal>

    </Wrapper>
  );
}

export default RedeemPage;
