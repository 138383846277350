
type MediaDeviceInfoArray = Array<MediaDeviceInfo>;

type VideoTrackInfo = {
    capabilities: MediaTrackCapabilities,
    settings: MediaTrackSettings,
    label: String,
};

type ExtendedVideoDeviceInfo = {
    device: MediaDeviceInfo,
    tracks: Array<VideoTrackInfo>,
};

type ExtendedVideoDeviceInfoArray = Array<ExtendedVideoDeviceInfo>;

type ExtendedMediaTrackCapabilities = MediaTrackCapabilities & {focusMode?: Array<String>, torch?: Boolean};


const getVideoDevices = async (): Promise<MediaDeviceInfoArray> => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(device => device.kind === 'videoinput');
};

const detectVideoDevice = async (): Promise<MediaDeviceInfo | undefined> => {
    const videoDevices = await getVideoDevices();
    for (const videoDevice of videoDevices) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: videoDevice.deviceId } } });
        for (const track of stream.getVideoTracks()) {
            const capabilities = track.getCapabilities() as ExtendedMediaTrackCapabilities;
            const environmentFacingMode = 'facingMode' in capabilities && capabilities.facingMode?.includes('environment');
            const continuousFocusMode = 'focusMode' in capabilities && capabilities.focusMode?.includes('continuous');
            const torch = 'torch' in capabilities && capabilities.torch;
            if (environmentFacingMode && continuousFocusMode && torch) {
                console.log('[MediaUtils] A suitable device has been auto-detected', videoDevice.label);
                stream.getTracks().forEach(track => track.stop());
                return videoDevice;
            }
        }
        stream.getTracks().forEach(track => track.stop());
    }
    console.log('[MediaUtils] Device auto-detection failed. Falling back to default option...');
    return;
};

const getVideoDevicesInfo = async (): Promise<ExtendedVideoDeviceInfoArray> => {
    const videoDevices = await getVideoDevices();
    let devicesInfo: ExtendedVideoDeviceInfoArray = [];
    for (const videoDevice of videoDevices) {
        let extendedDeviceInfo: ExtendedVideoDeviceInfo = {
            device: videoDevice,  // only `device.kind` will be available until permission is granted by user
            tracks: [],
        };
        const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: videoDevice.deviceId } } });
        for (const track of stream.getVideoTracks()) {
            const capabilities = track.getCapabilities();
            extendedDeviceInfo.tracks.push({
                label: track.label,
                capabilities: capabilities,
                settings: track.getSettings(),
            });
        }
        stream.getTracks().forEach(track => track.stop());
        devicesInfo.push(extendedDeviceInfo);
    }
    return devicesInfo;
};

export { getVideoDevicesInfo, detectVideoDevice };
