import styled from "styled-components";

export const SendWrapper = styled.div`
    position: absolute;
    top: 200px;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    /* justify-content: center; */

    flex-direction: column;
`