const Config = {
    environment: process.env.NODE_ENV,
    domain: "trustwise.io"
}

export default Config;

export enum Environment {
    DEMO = "development",
    PRODUCTION = "production"
}