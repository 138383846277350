import { useEffect, useState } from 'react';

import { 
  Wrapper, 
  ScreenTitleLabel 
} from "assets/styles/App.styles";

import {
  Button,
  InputField,
  Loader,
} from 'components';

import { AdditionalStyles } from "components/Button";

import ApiManager from 'core/ApiManager';
import Utils from 'utils/Utils';
import UserUtils from 'utils/UserUtils';

const LoginPage = (props: any) => {

  const [loading, setLoading] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  useEffect(() => {
    if (!Utils.isNullOrEmpty(UserUtils.getKey())) {
      props.history.replace(Utils.appendTestingParams("/account/"));
    }

  }, [props.history]);

  const showPasswordHandler = () => {
    let passwordField = (document.getElementById("txtPassword") as HTMLInputElement);
    let showPasswordButton = (document.getElementById("btnShow") as HTMLButtonElement);

    if (!isPasswordShown) {
      showPasswordButton.textContent = "Hide";
      passwordField.type = "text";
    } else {
      showPasswordButton.textContent = "Show";
      passwordField.type = "password";
    }

    setIsPasswordShown(!isPasswordShown);
  }

  const handleLogin = async () => {
    let email = (document.getElementById("txtEmail") as HTMLInputElement).value;
    let password = (document.getElementById("txtPassword") as HTMLInputElement).value;

    if (Utils.isNullOrEmpty(email) || !Utils.isValidEmail(email)) {
      alert("Please provide a valid email to login.");
      return;
    }

    if (Utils.isNullOrEmpty(password)) {
      alert("Please provide your password to login.");
      return;
    }

    setLoading(true);

    let loginResponse = await ApiManager.doLogin(email, password);
    setLoading(false);
    switch (loginResponse.status) {
      case 200:
        UserUtils.setKey(`${loginResponse.key}`);
        UserUtils.setMasterAddress(`${loginResponse.master_address}`);

        props.history.replace(Utils.appendTestingParams("/account/"));
        break;
      default:
        let errorMessage = "Login failed! Please check your credentials and try again.";
        if (loginResponse.non_field_errors && loginResponse.non_field_errors.length > 0) {
          errorMessage = loginResponse.non_field_errors[0];
        }
        alert(errorMessage);
    }
  }

  return (
    <Wrapper>
      <ScreenTitleLabel>Login Page</ScreenTitleLabel>

      <InputField id="txtEmail" placeholder="Email" type="email" />
      <InputField id="txtPassword" placeholder="Password" type="password" />

      <Button id="btnShow" hollow className="inline-button" onClick={showPasswordHandler}>Show</Button>
      <Button style={AdditionalStyles.loginButtonStyle} onClick={handleLogin}>Log In</Button>

      {loading && <Loader />}
    </Wrapper>
  );
}

export default LoginPage;
