import UserUtils from 'utils/UserUtils';
import Utils from 'utils/Utils';
import Config from './Config';

import {
    AccountBalanceResponse,
    BankNoteResponse,
    CreateTransferRequest,
    LoginResponse,
    RedeemRequest,
    Transaction,
    TransferResponse,
} from './Types';

const ApiManager = {
    // MOCK Server: https://d398b928-2905-429f-ae0f-c6d13c7997f4.mock.pstmn.io

    getApiVersion: (): number => {
        return 1;
    },

    getDomain: (): string => {
        let domainPrefix = "demo.";
        // if(Config.environment === Environment.PRODUCTION) {
        //     domainPrefix = "";
        // }

        return `https://${domainPrefix}${Config.domain}`;
    },

    doLogin: async(email: string, password: string) : Promise<LoginResponse> => {
        let url = `${ApiManager.getDomain()}/api/v${ApiManager.getApiVersion()}/auth/login/`;

        console.log("URL: ", url);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password })
        };

        let response = await fetch(url, requestOptions);
        console.log("response: ", response);

        try {
            let json = await response.json();
            let finalResponse: LoginResponse = {
                status: response.status,
                ...json
            };

            return finalResponse;
        } catch (error) {
            let finalResponse: LoginResponse = {
                status: response.status,
                error: error
            };

            return finalResponse;
        }
    },

    getAccountBalance: async(): Promise<AccountBalanceResponse> => {
        let url = `${ApiManager.getDomain()}/api/v${ApiManager.getApiVersion()}/cbdc/account/balance/`;
        url = Utils.appendTestingParams(url);

        console.log("URL: ", url);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${UserUtils.getKey()}` },
        };

        let response = await fetch(url, requestOptions);

        try {
            let json = await response.json();
            let finalResponse: AccountBalanceResponse = {
                status: response.status,
                ...json
            };

            return finalResponse;
        } catch (error) {
            let finalResponse: AccountBalanceResponse = {
                status: response.status,
                error: error
            };
            return finalResponse;
        }
    },

    getTransfers: async (no_of_items?: number): Promise<Transaction[]> => {
        let url = `${ApiManager.getDomain()}/api/v${ApiManager.getApiVersion()}/cbdc/account/transfers/`;

        if(no_of_items && no_of_items > 0) {
            url += `?no_of_items=${no_of_items}`;
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${UserUtils.getKey()}` },
        };

        url = Utils.appendTestingParams(url);

        console.log("URL: ", url);

        let response = await fetch(url, requestOptions);

        try {
            let json = await response.json();
            let finalResponse: Transaction[] = json;

            return finalResponse;
        } catch (error) {
            let finalResponse: Transaction[] = [];
            return finalResponse;
        }
    },

    createTransfer: async(auto_sign: boolean, request: CreateTransferRequest) : Promise<TransferResponse> => {
        let url = `${ApiManager.getDomain()}/api/v${ApiManager.getApiVersion()}/cbdc/account/transfers/`;

        if(auto_sign) {
            url += `?auto_sign=1`;
        }

        url = Utils.appendTestingParams(url);

        console.log("URL: ", url);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${UserUtils.getKey()}` },
            body: JSON.stringify(request)
        };

        let response = await fetch(url, requestOptions);
        
        try {
            let json = await response.json();
            let finalResponse: TransferResponse = {
                status: response.status,
                ...json
            };
            return finalResponse;
        } catch (error) {
            let finalResponse: TransferResponse = {
                status: response.status,
                error: error
            };

            return finalResponse;
        }
    },

    redeem: async(request: RedeemRequest) : Promise<TransferResponse> => {
        console.log("Redeem Payload: ", request);
        let url = `${ApiManager.getDomain()}/api/v${ApiManager.getApiVersion()}/cbdc/account/redeem/`;
        url = Utils.appendTestingParams(url);

        console.log("URL: ", url);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${UserUtils.getKey()}` },
            body: JSON.stringify(request)
        };

        let response = await fetch(url, requestOptions);

        try {
            let json = await response.json();
            let finalResponse: TransferResponse = {
                status: response.status,
                ...json
            };

            return finalResponse;
        } catch (error) {
            let finalResponse: TransferResponse = {
                status: response.status,
                error: error
            };

            return finalResponse;
        }
    },

    getBankNoteDetails: async (walletAddress: string): Promise<BankNoteResponse> => {
        let addressWithOxPrefix = walletAddress;
        if(walletAddress.indexOf('0x') === -1)  {
            addressWithOxPrefix = '0x' + walletAddress;
        }

        console.log("addressWithOxPrefix: ", addressWithOxPrefix);

        let url = `${ApiManager.getDomain()}/api/v${ApiManager.getApiVersion()}/cbdc/banknotes/${addressWithOxPrefix}`;
        url = Utils.appendTestingParams(url);

        console.log("URL: ", url);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${UserUtils.getKey()}` },
        };


        let response = await fetch(url, requestOptions);

        try {
            let json = await response.json();
            let finalResponse: BankNoteResponse = {
                status: response.status,
                data: json
            };

            return finalResponse;
        } catch (error) {
            let finalResponse: BankNoteResponse = {
                status: response.status,
                data: error
            };

            return finalResponse;
        }
    },

    getTransactionStatus: async (hash: string): Promise<any> => {
        let url = `${ApiManager.getDomain()}/api/v${ApiManager.getApiVersion()}/transactions/status/${hash}`;
        url = Utils.appendTestingParams(url);

        console.log("URL: ", url);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${UserUtils.getKey()}` },
        };

        let response = await fetch(url, requestOptions);

        try {
            return await response.text();
        } catch (error) {
            return {
                status: response.status,
                error: error
            };
        }
    },
}

export default ApiManager;
