import { Constants } from "./Constants";

const UserUtils = {
    getKey: (): string => {
        return `${sessionStorage.getItem(Constants.KEY)}`;
    },
    setKey: (key: string) => {
        sessionStorage.setItem(Constants.KEY, key);
    },

    getMasterAddress: (): string => {
        return `${sessionStorage.getItem(Constants.MASTER_ADDRESS)}`;
    },
    setMasterAddress: (masterAddress: string) => {
        sessionStorage.setItem(Constants.MASTER_ADDRESS, masterAddress);
    },

    getAddress: (): string => {
        return `${sessionStorage.getItem(Constants.ADDRESS)}`;
    },
    setAddress: (address: string) => {
        sessionStorage.setItem(Constants.ADDRESS, address);
    },

    getBalanceAmount: (): number => {
        return Number(sessionStorage.getItem(Constants.BALANCE));
    },
    setBalanceAmount: (balance: number) => {
        sessionStorage.setItem(Constants.BALANCE, `${balance}`);
    },

    getCurrencyCode: (): string => {
        return `${sessionStorage.getItem(Constants.CURRENCY_CODE)}`;
    },
    setCurrencyCode: (currencyCode: string) => {
        sessionStorage.setItem(Constants.CURRENCY_CODE, currencyCode);
    },
}

export default UserUtils;