// Button.tsx
import React from "react";
import styled from "styled-components";

import Colors from "assets/styles/Colors";
import { Link } from "react-router-dom";
import Utils from "utils/Utils";

interface IBackButtonProps {
    children?: React.ReactNode;
    props?: any;
    to?: string;
}

const BackButton: React.FC<IBackButtonProps> = (props) => {
    const backAddress: string = Utils.appendTestingParams((props && props.to) ? props.to : "/account/");
    return (
        <BackButtonStyles>
            <Link to={backAddress}>Back</Link>
        </BackButtonStyles>
    );
};

export default BackButton;

const BackButtonStyles = styled.button`
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: 10;
    width: 60;
    border: none;
    color: ${Colors.ACTIVE_BLUE};
    background-color: ${Colors.WHITE};
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    height: 46px;
    text-align: center;
    margin-bottom: 10px;
    white-space: nowrap;

    a {
        text-decoration: none;
    }

    &:active {
        transform: scale(0.98);
    }
`;