import styled from "styled-components";
import Colors from "assets/styles/Colors";

export const ButtonWrapper = styled.div`
    position: absolute;
    top: 320px;
`

export const AddressBox = styled.div`
    position: absolute;
    top: 100px;
    width: 96%;
    padding: 13px;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 8px;

    .title {
        width: 100%;
        margin-bottom: 17px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        text-align: left;
        color: ${Colors.TITLE_LABEL_COLOR};
        float: left;
    }

    .addressWrapper {
        float: left;
        width: 100%;
    }

    .address {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        align-self: stretch;
        color: ${Colors.DEFAULT_TEXT_COLOR};
        display:inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 339px) {
        .address {
            width: 23ch;
        }
    }

    @media screen and (min-width: 340px) and (max-width: 360px) {
        .address {
            width: 25ch;
        }
    }

    @media screen and (min-width: 361px) and (max-width: 400px) {
        .address {
            width: 28ch;
        }
    }

    @media screen and (min-width: 401px) and (max-width: 440px) {
        .address {
            width: 32ch;
        }
    }

    @media screen and (min-width: 441px) and (max-width: 480px) {
        .address {
            width: 36ch;
        }
    }

    @media screen and (min-width: 481px) and (max-width: 520px) {
        .address {
            width: 40ch;
        }
    }

    .iconWrapper {
        position: absolute;
        right: 17px;
        top: 42px;
    }

    .icon {
        height: 20px;
        width: 20px;
        margin-left: 23px;
    }

    .icon:active {
        transform: scale(0.95);
    }
`;

export const MyAccountTransactionListWrapper = styled.div`
    position: absolute;
    top: 380px;
    width: 96%;
`

export const ModalAddressBoxWrapper = styled.div`
    height: 100px;
    width: 105%;
    position: relative;
    bottom: 100px;
`

export const QRCodeWrapper = styled.div`
    align-content: center;
    justify-content: center;

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;

        height: 235px;
        width: 235px;
    }

    @media screen and (min-width: 400px) and (max-width: 560px) {
        img {
            height: 350px;
            width: 350px;
        }
    }

    @media screen and (min-width: 561px) {
        img {
            height: 450px;
            width: 450px;
        }
    }
`