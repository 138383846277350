import React, { useState, useEffect } from 'react';
import { setTimeout } from 'timers';

import { DebugAreaContent, DebugAreaWrapper, DebugCopyButton } from "../assets/styles/MediaDebugger.styles";
import { getVideoDevicesInfo } from './MediaUtils';


const MediaDebugger = (props: any) => {
    const defaultCopyButtonText = 'Copy to clipboard';
    const [areaValue, setAreaValue] = useState('');
    const [copyButtonText, setCopyButtonText] = useState(defaultCopyButtonText);
    const copyHandler = () => {
        navigator.clipboard.writeText(areaValue);
        setCopyButtonText('Copied!');
        setTimeout(() => { setCopyButtonText(defaultCopyButtonText); }, 2000);
    }
    useEffect(() => {
        const setDevicesInfo = async () => {
            const devicesInfo = await getVideoDevicesInfo();
            setAreaValue(JSON.stringify(devicesInfo, null, 2));
        };
        setDevicesInfo();
    }, []);
    if (!areaValue) {
        return null;
    }
    return (
        <DebugAreaWrapper>
            <DebugCopyButton onClick={copyHandler}>{copyButtonText}</DebugCopyButton>
            <DebugAreaContent><pre>{areaValue}</pre></DebugAreaContent>
        </DebugAreaWrapper>
    );
};


export default MediaDebugger;
