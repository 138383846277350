import { Images } from 'assets/images';

import {
    TransactionListWrapper,
    TransactionsWrapper,
    NoTransactionsImage,
    NoTransactionsWrapper,
    NoTransactionsTitle,
    NoTransactionsDesc,
    NoTransactionsFooterWrapper,
} from 'assets/styles/TransactionList.styles';

import TransactionItem from "./Transaction";

import Button, { AdditionalStyles } from "./Button";
import { Transaction } from "core/Types";

const TransactionList = (props: any) => {
    const seeAllHandler = () => {
        console.log("sellAllHandler called");
        if (props.seeAllHandler) {
            props.seeAllHandler();
        } else {
            console.log("")
        }
    }

    return (
        <TransactionListWrapper>
            <div>
                {props.seeAllHandler && <Button hollow style={AdditionalStyles.seeAllButtonStyle} onClick={seeAllHandler}>See all</Button>}
            </div>
            <div>
                {props.transactions && props.transactions.length === 0 &&
                    <NoTransactionsWrapper>
                        <NoTransactionsImage src={Images.no_transactions} alt="" />
                        <NoTransactionsFooterWrapper>
                            <NoTransactionsTitle>No transactions</NoTransactionsTitle>
                            <NoTransactionsDesc>Redeem your first UDL token or request a transfer.</NoTransactionsDesc>
                        </NoTransactionsFooterWrapper>
                    </NoTransactionsWrapper>
                }

                {props.transactions && props.transactions.length > 0 &&
                    <TransactionsWrapper>
                        {
                            props.transactions.map((transaction: Transaction, index: number) => {
                                return <TransactionItem
                                    key={index}
                                    timestamp={Number(transaction.timestamp)}
                                    amount={transaction.amount}
                                    action={transaction.action}
                                    counterparty={transaction.counterparty}
                                />
                            })
                        }
                    </TransactionsWrapper>
                }
            </div>
        </TransactionListWrapper>
    );
}

export default TransactionList;
