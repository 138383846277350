import React from "react";

import { Button } from "components";
import { AdditionalStyles } from "components/Button";
import MediaDebugger from 'utils/MediaDebugger';

import {
  ScreenTitleLabel,
  Wrapper,
} from "assets/styles/App.styles";


const DebugPage = (props: any) => {

  const backHandler = () => {
    props.history.goBack();
  }

  return (
    <Wrapper>
      <Button hollow style={AdditionalStyles.backButtonStyle} onClick={backHandler}>Back</Button>
      <ScreenTitleLabel>Technical Info</ScreenTitleLabel>
      <MediaDebugger />
    </Wrapper>
  );
}

export default DebugPage;
