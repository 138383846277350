import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { Button, BackButton, InputField, Loader } from "components";
import { AdditionalStyles } from "components/Button";

import {
  Wrapper,
  ScreenTitleLabel,
  ConfirmationHeaderLabel,
  ConfirmationValueLabel,
  ConfirmationButtonWrapper,
  SendConfimrationContentWrapper
} from "assets/styles/App.styles";

import { SendWrapper } from 'assets/styles/SendPage.styles';

import { Images } from 'assets/images';

import { Constants } from 'utils/Constants';
import { CreateTransferRequest, TransactionStatus } from 'core/Types';

import ApiManager from 'core/ApiManager';

import Utils from 'utils/Utils';
import UserUtils from 'utils/UserUtils';

const SendPage = (props: any) => {

  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [sendAddress, setSendAddress] = useState("");
  const [amount, setAmount] = useState(0.0);
  const code = "UDL"; // TODO: Check if this can be retrieved based on the user's currency

  let checkStatusInterval: NodeJS.Timeout | null = null;

  // Redirect to login if there is no session item for key
  useEffect(() => {
    if (Utils.isNullOrEmpty(UserUtils.getKey())) {
      props.history.replace(Utils.appendTestingParams("/login/"));
    }
  }, [props.history]);

  const toggleModal = () => {
    setShowConfirmation(!showConfirmation);
  }

  const sendClickHandler = () => {
    if (Utils.isNullOrEmpty(sendAddress)) {
      return alert("Please enter a valid recipient address to continue.");
    }

    let enteredAmount: number = Number((document.getElementById("txtAmount") as HTMLInputElement).value);
    if (!enteredAmount || enteredAmount === 0) {
      return alert("Please enter a valid amount to continue.");
    }

    setAmount(enteredAmount);

    setShowConfirmation(true);
  }

  const getTransactionStatus = async (txHash: string) => {
    if (!loading) {
      setLoading(true);
    }
    let status = await ApiManager.getTransactionStatus(txHash);
    if (status === TransactionStatus.PROCESSED) {
      setLoading(false);
      if (checkStatusInterval) {
        clearInterval(checkStatusInterval);
      }
      props.history.goBack();
    }
  }

  const onAddressChange = (event: any) => {
    setSendAddress(event.target.value);
  }

  const scanClickHandler = () => {
    props.history.push(Utils.appendTestingParams("/scan/"));
  }

  useEffect(() => {
    let scannedAddress = sessionStorage.getItem(Constants.SEND_ADDRESS);
    if (scannedAddress) {
      setSendAddress(scannedAddress);
      sessionStorage.setItem(Constants.SEND_ADDRESS, ""); // Clear address
    }
  }, []);

  const signTransaction = async () => {
    setShowConfirmation(false);
    let amount: number = Number((document.getElementById("txtAmount") as HTMLInputElement).value);

    let request: CreateTransferRequest = {
      recipient: sendAddress,
      amount: amount,
    };

    setLoading(true);
    let sendResponse = await ApiManager.createTransfer(true, request);

    if (sendResponse.tx_hash) {
      checkStatusInterval = setInterval(() => {
        getTransactionStatus(`${sendResponse.tx_hash}`)
      }, 1000);
    } else {
      alert("An error occurred while sending the amount, please try again.")
      setLoading(false);
    }

  }

  const cancelTransaction = () => {
    setShowConfirmation(false);
  }

  return (
    <Wrapper>
      <BackButton />
      <ScreenTitleLabel>Send</ScreenTitleLabel>

      <SendWrapper>
        <InputField
          placeholder="Address"
          id="txtAddress"
          rightIcon={Images.ic_scan}
          type="text"
          value={sendAddress}
          onChange={onAddressChange} />

        <img
          className="inline-icon"
          src={Images.ic_scan}
          onClick={scanClickHandler}
          alt="Scan" />

        <InputField
          placeholder="Amount"
          id="txtAmount"
          type="number" />
        <span className="inline-text">UDL</span>

        <Button style={AdditionalStyles.loginButtonStyle} onClick={sendClickHandler}>Send</Button>
      </SendWrapper>

      {loading && <Loader />}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirmation} onHide={toggleModal}>
        <Modal.Body>
          <SendConfimrationContentWrapper>
            <ConfirmationHeaderLabel>Recipient</ConfirmationHeaderLabel>
            <ConfirmationValueLabel>{sendAddress}</ConfirmationValueLabel>
            <ConfirmationHeaderLabel>Amount</ConfirmationHeaderLabel>
            <ConfirmationValueLabel>{amount} {code}</ConfirmationValueLabel>
          </SendConfimrationContentWrapper>
          <ConfirmationButtonWrapper>
            <Button onClick={signTransaction}>Confirm</Button> <br />
            <Button hollow onClick={cancelTransaction}>Cancel</Button>
          </ConfirmationButtonWrapper>
        </Modal.Body>
      </Modal>

    </Wrapper>
  );
}

export default SendPage;
