import { ECDSASignature, ecsign, privateToAddress as privateToAddress_eth } from 'ethereumjs-util';
import { getMessage, TypedData } from 'eip-712';

const _generateEip712Struct = (
    domainName: string,
    domainVersion: string,
    chainId: string,
    banknoteAddress: string,
    recipientAddress: string,
    contractAddress: string
): TypedData  => {
    return {
        types: {
            EIP712Domain: [
                {name: 'name', type: 'string'},
                {name: 'version', type: 'string'},
                {name: 'chainId', type: 'uint256'},
                {name: 'verifyingContract', type: 'address'},
            ],
            BanknoteTransferStruct: [
                {name: 'holder', type: 'address'},
                {name: 'recipient', type: 'address'},
            ],
        },
        primaryType: 'BanknoteTransferStruct',
        domain: {
            name: domainName,
            version: domainVersion,
            chainId: chainId,
            verifyingContract: contractAddress,
        },
        message: {
            holder: banknoteAddress,
            recipient: recipientAddress,
        },
    };
}

export const privateToAddress = (privateKey: Buffer): string => (
    '0x' + privateToAddress_eth(privateKey).toString('hex')
);

export const signBanknoteTransfer = (
    privateKey: Buffer,
    chainId: string,
    recipientAddress: string,
    contractAddress: string
): Buffer => {
    // VERSION 1
    const domainName: string = 'BANKNOTE UTOPIA DUAL';
    const domainVersion: string = '1';

    const banknoteAddress: string = privateToAddress(privateKey);
    const data = _generateEip712Struct(
        domainName, domainVersion, chainId, banknoteAddress, recipientAddress, contractAddress
    );
    const messageHash: Buffer = getMessage(data, true);
    const signature: ECDSASignature = ecsign(messageHash, privateKey);
    // To get hexstring for API just do `'0x' + result.toString('hex')`
    return Buffer.concat([signature.r, signature.s, Buffer.from([signature.v])])
}
