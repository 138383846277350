import styled from "styled-components";
import Colors from "./Colors";

export const DebugAreaWrapper = styled.div`
    width: 90%;
    max-width: 600px;
    margin: 36px 0;
    font-family: monospace;
    text-align: right;
    font-size: 12px;
    color: ${Colors.LIGHT_GRAY};
`

export const DebugAreaContent = styled.div`
    text-align: left;
    box-sizing: border-box;
    padding: 12px;
    overflow: scroll;
    max-height: 500px;
    border: 1px dashed ${Colors.LIGHT_GRAY};
`

export const DebugCopyButton = styled.span`
    display: inline-block;
    padding: 12px 0;
    cursor: pointer;
`

export const DebugPageLink = styled.span`
    margin-top: 36px;
    font-family: monospace;
    display: inline-block;
    padding: 6px 0;
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    color: ${Colors.LIGHT_GRAY};
    position: fixed;
    bottom: 12px;
    z-index: 20;
`
