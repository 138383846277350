import { toChecksumAddress } from 'ethereumjs-util';

import { privateToAddress, signBanknoteTransfer } from '../core/BankNoteSigner';
import { decodePrivateQr } from 'core/QRDecoder';
import { RedeemRequest } from 'core/Types';
import UserUtils from 'utils/UserUtils';

const RedeemUtils = {
    getApiParams: (qrData: number[]): RedeemRequest | null => {
        let redeemRequest: RedeemRequest | null = null;
        try {
            var privateData = decodePrivateQr(qrData);
            let address = privateToAddress(privateData.privateKey);
            console.log("address: ", address);
            console.log("privateData", privateData);
            let signedBankNoteBuffer = signBanknoteTransfer(privateData.privateKey, privateData.chainId, UserUtils.getAddress(), privateData.contractAddress);
            let signature = `${'0x' + signedBankNoteBuffer.toString('hex')}`
            console.log("signature: ", signature);
            redeemRequest = {
                banknote_address: toChecksumAddress(address),
                signature: signature,
                chain_id: privateData.chainId,
                contract_address: toChecksumAddress(privateData.contractAddress)
            }

            console.log("redeemRequest: ", redeemRequest);
        } catch(error) {
            // TODO: Report error to user
            console.log("error: ", error);
        }

        return redeemRequest;
    },
}

export default RedeemUtils;