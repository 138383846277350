import styled from "styled-components";
import Colors from "assets/styles/Colors";

export const RedeemWrapper = styled.div`
    position: absolute;
    top: 140px;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    /* justify-content: center; */

    flex-direction: column;
`

export const UnorderedListItem = styled.ul`
    width: 80%;
    line-height: 18px;
`

export const ListItem = styled.li`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    color: ${Colors.DEFAULT_TEXT_COLOR};
`
