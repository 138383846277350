import ic_copy from './ic_copy@3x.png';
import ic_redeem from './ic_redeem@3x.png';
import ic_scan from './ic_scan@3x.png';
import ic_send from './ic_send@3x.png';
import no_transactions from './no_transactions@3x.png';
import loader from './loader.gif';

export const Images = {
    ic_copy,
    ic_redeem,
    ic_scan,
    ic_send,
    no_transactions,
    loader
}