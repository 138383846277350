const Utils = {
    isNullOrEmpty: (valueToCheck: any): boolean => {
        return (valueToCheck === undefined)
            || (valueToCheck === "undefined")
            || (valueToCheck === null)
            || (valueToCheck === "null")
            || (valueToCheck === {})
            || (valueToCheck === [])
            || (valueToCheck === "");
    },

    isValidEmail: (valueToCheck: string): boolean => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(valueToCheck).toLowerCase());
    },

    hasGetUserMedia: (): boolean => {
        return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    },

    appendTestingParams: (path: string): string => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchParamsPath = new URLSearchParams();
        const tokenTestAddress = searchParams.get('_banknotes_controller_token');
        const controllerTestAddress = searchParams.get('_banknotes_controller_address');
        tokenTestAddress && searchParamsPath.set('_banknotes_controller_token', tokenTestAddress);
        controllerTestAddress && searchParamsPath.set('_banknotes_controller_address', controllerTestAddress);
        const searchParamsPathString = searchParamsPath.toString();
        
        if(path.indexOf("?") === -1) {
            return searchParamsPathString ? `${path}?${searchParamsPathString}` : path;
        } else {
            return searchParamsPathString ? `${path}&${searchParamsPathString}` : path;
        }
    }
}

export default Utils;