import styled from "styled-components";

export const Item = styled.div`
    width: 96%;
    margin-bottom: 40px;
`

export const TransactionDetail = styled.span`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-top:19px; 
    align-items: flex-center;
    width:100%;
`

export const TransactionLabel = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
`

export const TransactionAmount = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #333333;
`

export const DateLabel = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #999999;
`

export const TransactionsHeader = styled.span`
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #08224A;
`

export const TransactionsImageWrapper = styled.span`
    align-self: center;
    margin-top: 46.2px;
`

export const TransactionsFooterWrapper = styled.span`
    display: flex;
    flex-direction: column; 
    align-items: center;
    align-self: center;
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
`

export const TransactionsFooterTitle = styled.span`
    margin-top: 20px;
    align-self: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #666666;

`

export const TransactionsFooterDesc = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    margin-top: 8px;
    line-height: 18px;
    text-align: center;
    width:65%;
    color: #666666;

`