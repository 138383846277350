// Button.tsx
import React from "react";
import styled from "styled-components";
import _ from "lodash";

import Colors from "assets/styles/Colors";

interface IButtonProps {
    id?: string;
    children?: React.ReactNode;
    props?: any;
    disabled?: boolean;
    hollow?: boolean;
    square?: boolean;
    onClick?: any;
    style?: any;
    className?: any;
}

const Button: React.FC<IButtonProps> = ({ onClick, hollow, disabled, square, children, style, ...props }) => {
    const getAdditionalStyle: any = () => {
        let additionalStyle = {
            background: Colors.ACTIVE_BLUE,
            color: Colors.WHITE,
            borderRadius: 100,
            maxWidth: 310,
            width: 310,
        };

        if (disabled) {
            additionalStyle.background = Colors.INACTIVE_BLUE;
        }

        if (hollow) {
            additionalStyle.background = Colors.WHITE;
            additionalStyle.color = Colors.ACTIVE_BLUE;
        }

        if (square) {
            additionalStyle.borderRadius = 10;
            additionalStyle.maxWidth = 152;
            additionalStyle.width = 152;
        }

        // add styles passed as params
        additionalStyle = _.merge(additionalStyle, style);

        return additionalStyle;
    }

    return (
        <ButtonStyles disabled={disabled} style={getAdditionalStyle()} onClick={onClick} {...props}>
            {children}
        </ButtonStyles>
    );
};

export default Button;

const ButtonStyles = styled.button`
    border: none;
    color: ${Colors.WHITE};
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    height: 46px;
    text-align: center;
    margin-bottom: 10px;
    white-space: nowrap;

    &:active {
        transform: scale(0.98);
    }

    > img {
        height: 16.5px;
        position: relative;
        top: -2px;
        left: 6px;
    }
`;

export const AdditionalStyles = {
    backButtonStyle: {
        width: 60, position: 'absolute', top: '40px', left: '20px', zIndex: 10,
    },
    seeAllButtonStyle: {
        width: 60, height: 30,
        position: 'absolute', top: 30, right: -4,
    },
    loginButtonStyle: {
        marginTop: 56
    },
}