import { BrowserRouter, Route, Switch } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import MyAccountPage from "./pages/MyAccountPage";
import RedeemPage from "./pages/RedeemPage";
import ScanQrCodePage from "./pages/ScanQrCodePage";
import SendPage from "./pages/SendPage";
import TransactionHistoryPage from "./pages/TransactionHistoryPage";
import DebugPage from "./pages/DebugPage";

const App = () => {

  return (

    <BrowserRouter>
      <div className="root">
        <Switch>
          <Route path="/" component={LoginPage} exact />
          <Route path="/login/" component={LoginPage} exact />
          <Route path="/account/" component={MyAccountPage} exact />
          <Route path="/redeem/" component={RedeemPage} exact />
          <Route path="/scan/" component={ScanQrCodePage} exact />
          <Route path="/send/" component={SendPage} exact />
          <Route path="/transactions/" component={TransactionHistoryPage} exact />
          <Route path="/debug/" component={DebugPage} exact />
        </Switch>

      </div>
    </BrowserRouter >
  );
}

export default App;
