export type LoginResponse = {
    status: number;
    key?: string;
    wallet_type?: string;
    master_address?: string;
    hd_wallet_count?: number;
    non_field_errors?: any;
    error?: any;
}

export type AccountBalanceResponse = {
    status: number;
    address?: string;
    balance?: number;
    currency_code?: string;
    error?: any;
}

export type CreateTransferRequest = {
    recipient: string;
    amount: number;
}

export type TransferResponse = {
    status: number;
    tx_hash?: string;
    error?: any;
}

export type RedeemRequest = {
    banknote_address: string;
    signature: string;
    chain_id: string;
    contract_address: string;
}

export type BankNoteResponse = {
    status: number;
    data: any
}

export type Transaction = {
    timestamp: number;
    action: string;
    amount: number;
    counterparty: string;
}

export enum TransactionStatus {
    UNKNOWN = "\"unknown\"",
    ACCEPTED = "\"accepted\"",
    MINED = "\"mined\"",
    PROCESSED = "\"processed\""
}