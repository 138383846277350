import {
    LoaderWrapper,
    // Message 
} from 'assets/styles/Loader.styles';
import { Images } from 'assets/images';

const Loader = (props: any) => {
    return (
        <LoaderWrapper>
            <img src={Images.loader} alt="Loading..." />
            {/* <Message>{(props.message ? props.message : "Loading...")}</Message> */}
        </LoaderWrapper>
    );
}

export default Loader;
