import { useState, useEffect } from 'react';
import Reader from 'react-qr-reader-binary';

import { detectVideoDevice } from 'utils/MediaUtils';


const QrReader = (props: any) => {
  const [videoDeviceId, setVideoDeviceId] = useState<String | undefined>('');

  useEffect(() => {
    (async () => {
      const device = await detectVideoDevice();
      setVideoDeviceId(device ? device.deviceId : undefined);
    })()
  }, []);

  return videoDeviceId !== '' ? (
    <Reader
        constraints={{
          facingMode: { ideal: 'environment' },
          focusMode: { ideal: 'continuous' },
          deviceId: videoDeviceId,
        }}
        delay={250}
        style={{ width: '80vw', maxWidth: 300 }}
        {...props}
    />
  ) : null;
};

export default QrReader;
