import styled, { createGlobalStyle } from "styled-components";
import Colors from "assets/styles/Colors";

export const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
        width: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    * {
        font-family: 'Roboto', sans-serif;
    }

    .back {
        width: 60px;
        position: 'absolute';
        top: '40px';
        left: '20px';
    }
`

export const Wrapper = styled.div`
    padding: 10px;
    height:100vh;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    .icon {
        height: 20px;
        width: 20px;
        position: relative;
    }

    .icon:active {
        transform: scale(0.95);
    }

    .inline-text {
        position: relative;
        top: -42px;
        right: -134px;
        margin-bottom: -20px;
        font-family: Roboto;
        font-style: normal;
        font-size: 15px;
    }

    .inline-icon {
        position: relative;
        top: -42px;
        right: -134px;
        height: 20px;
        width: 20px;
    }

    .inline-button {
        position: relative;
        top: -45px;
        right: -115px;
        max-width: 60px !important;
        height: 20px;
        font-weight: 100;
    }
`

export const ScreenTitleLabel = styled.span`
    position: absolute;
    top: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: ${Colors.TITLE_COLOR};
`

export const LoadingLabel = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
`

export const CodeBalance = styled.span`
    position: absolute;
    top: 240px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${Colors.TITLE_COLOR};
`
export const BoldBalanceText = styled.span`
    font-family: Roboto;
    margin-left: 4px;
    margin-right: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 41px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${Colors.TITLE_COLOR};
`

export const Message = styled.span`
    margin-top: 68px;
    margin-bottom: 125px;
    word-wrap: break-word;
    width: 250px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`

export const HeaderLabel = styled.span`
    position: absolute;
    top: 180px;
    width: 100%;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: ${Colors.TITLE_COLOR};
`

export const Label = styled.span`
    width: 100%;
    margin: 0 0 12px 36px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: ${Colors.DEFAULT_TEXT_COLOR};
`

export const RedeemConfirmationContentWrapper = styled.div`
    text-align: center;
    height: 80px;
`

export const RedeemConfirmationMessage = styled.span`
    display: block;
    font-size: 17px;
    margin-top: 20px;
    margin-bottom: 40px;
`

export const ConfirmationButtonWrapper = styled.p`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`

export const ConfirmationHeaderLabel = styled.span`
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: ${Colors.TITLE_LABEL_COLOR};
`

export const ConfirmationValueLabel = styled.span`
    width: 100%;
    margin-bottom: 16px;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: ${Colors.TITLE_LABEL_COLOR};
`

export const SendConfimrationContentWrapper = styled.div`
    height: 170px;
`