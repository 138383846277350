import { useEffect, useState } from 'react';
import QRCodeGenerator from 'qrcode-generator';
import { Modal } from 'react-bootstrap';

import {
  BoldBalanceText,
  CodeBalance,
  HeaderLabel,
  ScreenTitleLabel,
  Wrapper,
} from "assets/styles/App.styles";

import {
  AddressBox,
  ButtonWrapper,
  MyAccountTransactionListWrapper,
  ModalAddressBoxWrapper,
  QRCodeWrapper,
} from "assets/styles/MyAccount.styles";

import { Images } from 'assets/images';

import {
  Button,
  Loader,
  TransactionList
} from 'components';

import ApiManager from 'core/ApiManager';
import { AccountBalanceResponse, Transaction } from 'core/Types';

import Utils from 'utils/Utils';
import UserUtils from 'utils/UserUtils';

const MyAccountPage = (props: any) => {

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [balance, setBalance] = useState<number | undefined>(0);
  const [currency, setCurrency] = useState<string | undefined>("");
  const [address, setAddress] = useState<string | undefined>("");

  // Redirect to login if there is no session item for key
  useEffect(() => {
    if (Utils.isNullOrEmpty(UserUtils.getKey())) {
      props.history.replace(Utils.appendTestingParams("/login/"));
    }
  }, [props.history]);

  const sendHandler = () => {
    props.history.push(Utils.appendTestingParams("/send/"));
  }

  const redeemHandler = () => {
    props.history.push(Utils.appendTestingParams("/redeem/"));
  }

  const seeAllHandler = () => {
    props.history.push(Utils.appendTestingParams("/transactions/"));
  }

  const copyHandler = () => {
    navigator.clipboard.writeText(`${address}`);
  }
  const scanHandler = () => {
    toggleModal();
  }

  const shouldDisableSendButton = (): boolean => {
    return !(Number(balance) > 0);
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const generateQRCode = () => {
    let qr = QRCodeGenerator(4, 'L');
    qr.addData(UserUtils.getAddress());
    qr.make();
    return qr.createImgTag(11, 50);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let accoutBalance: AccountBalanceResponse = await ApiManager.getAccountBalance();

      setBalance(accoutBalance.balance);
      setCurrency(accoutBalance.currency_code);
      setAddress(accoutBalance.address);

      UserUtils.setBalanceAmount(Number(accoutBalance.balance));
      UserUtils.setCurrencyCode(`${accoutBalance.currency_code}`);
      UserUtils.setAddress(`${accoutBalance.address}`);

      let transfers = await ApiManager.getTransfers(5)
      setTransactions(transfers);
      let transactionsHeaderLabel = document.getElementById("transactionsHeaderLabel");
      if (transactionsHeaderLabel) {
        transactionsHeaderLabel.style.top = "40px";
      }

      setLoading(false);
    })()
  }, []);

  return (
    <Wrapper>
      <ScreenTitleLabel>My Account</ScreenTitleLabel>
      
      <AddressBox>
        <span className="title">Address</span>
        <div className="addressWrapper">
          <span className="address">{address}</span>
          <div className="iconWrapper">
            <img className="icon" src={Images.ic_copy} onClick={copyHandler} alt="Copy" />
            <img className="icon" src={Images.ic_scan} onClick={scanHandler} alt="Scan" />
          </div>
        </div>
      </AddressBox>
      
      <CodeBalance>
        <BoldBalanceText>{balance}</BoldBalanceText> {currency}
      </CodeBalance>

      <ButtonWrapper>
        <Button id="btnSend" square={true} disabled={shouldDisableSendButton()} onClick={sendHandler} style={{ marginRight: 12 }}>
          Send
          <img src={Images.ic_send} alt="Send" />
        </Button>
        <Button square={true} onClick={redeemHandler} style={{ marginLeft: 12 }}>
          Redeem
          <img src={Images.ic_redeem} alt="Redeem" />
        </Button>
      </ButtonWrapper>

      <MyAccountTransactionListWrapper>
        <HeaderLabel id="transactionsHeaderLabel">Transactions</HeaderLabel>
        <TransactionList transactions={transactions} seeAllHandler={seeAllHandler} />
      </MyAccountTransactionListWrapper>
      
      {loading && <Loader />}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal} onHide={toggleModal}>
        <Modal.Body>
          <QRCodeWrapper
            dangerouslySetInnerHTML={{ __html: generateQRCode() }} />

          <ModalAddressBoxWrapper>
            <AddressBox>
              <span className="title">Address</span>
              <div className="addressWrapper">
                <span className="address">{address}</span>
                <div className="iconWrapper">
                  <img className="icon" src={Images.ic_copy} onClick={copyHandler} style={{marginTop: -6}} alt="Copy" />
                </div>
              </div>
            </AddressBox>
          </ModalAddressBoxWrapper>
        </Modal.Body>
      </Modal>

    </Wrapper>
  );
}

export default MyAccountPage;
