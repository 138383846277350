import { useEffect, useState } from 'react';
import { BackButton, Loader, TransactionList } from "components";

import {
  ScreenTitleLabel,
  Wrapper,
} from "assets/styles/App.styles";

import { AllTransactionListWrapper } from 'assets/styles/TransactionHistoryPage.styles';

import { Transaction } from 'core/Types';
import ApiManager from "core/ApiManager";

import Utils from 'utils/Utils';
import UserUtils from 'utils/UserUtils';

const TransactionHistoryPage = (props: any) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);

  // Redirect to login if there is no session item for key
  useEffect(() => {
    if (Utils.isNullOrEmpty(UserUtils.getKey())) {
      props.history.replace(Utils.appendTestingParams("/login/"));
    }
  }, [props.history]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let transfers = await ApiManager.getTransfers(0);
      setLoading(false);
      setTransactions(transfers);
    })()
  }, []);

  return (
    <Wrapper>
      <BackButton />
      <ScreenTitleLabel>Transactions</ScreenTitleLabel>

      <AllTransactionListWrapper>
        <TransactionList transactions={transactions} />
      </AllTransactionListWrapper>

      {loading && <Loader />}

    </Wrapper>
  );
}

export default TransactionHistoryPage;
