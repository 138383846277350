// Button.tsx
import React from "react";
import styled from "styled-components";

import Colors from "assets/styles/Colors";

export interface InputFieldProps {
    placeholder?: string;
    id?: string;
    type?: string;
    value?: string;
    onChange?: any;
    rightIcon?: string;
    rightText?: string;
}

const Button: React.FC<InputFieldProps> = ({ placeholder, id, type, value, onChange, rightIcon, rightText, children, ...props }) => {
    return (
        <InputField 
          id={id} 
          type={type} 
          value={value}
          onChange={onChange}
          placeholder={placeholder} 
         />
    );
};

export default Button;

const InputField = styled.input`
  height: 46px;
  width: 310px;
  max-width: 310px;
  padding: 13px 16px;
  font-size: 18px;
  padding: 10px 38px 10px 10px;
  margin: 10px;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 8px;
  background: ${Colors.WHITE};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:active {
    background: ${Colors.WHITE};
  }

  ::placeholder {
    color: ${Colors.PLACEHOLDER_TEXT_COLOR};
  }
`;  