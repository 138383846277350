import styled from "styled-components";
import Colors from './Colors';

export const LoaderWrapper = styled.div`
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    z-index: 100; 
    background-color: #C1C1C1;
    opacity: 0.5;
    height:100vh;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    justify-content: center;
`

export const Message = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    margin-left: 12px;
    font-size: 1rem;
    color: ${Colors.BLACK};
`