import styled from "styled-components";

export const TransactionListWrapper = styled.div`
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: flex-start;
    justify-content: center;
`

export const TransactionsWrapper = styled.div`
    position: absolute;
    top: 84px;
    left: 10px;
    overflow-y:scroll;
    width:100%;
`
export const NoTransactionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;
    position: relative;
    /* top: 150px; */
`

export const NoTransactionsImage = styled.img`
    height: 200px;
    width: 200px;
    align-self: center;
    margin-top: 46.2px;
`

export const NoTransactionsFooterWrapper = styled.span`
    display: flex;
    flex-direction: column; 
    align-items: center;
    align-self: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
`

export const NoTransactionsTitle = styled.span`
    align-self: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #666666;

`
export const NoTransactionsDesc = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    margin-top: 8px;
    line-height: 18px;
    text-align: center;
    width:65%;
    color: #666666;

`