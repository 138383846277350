export type PrivateQrData = {
    version: number;
    privateKey: Buffer;
    contractAddress: string;
    chainId: string;
};


export const decodePrivateQr = (qrData: number[]): PrivateQrData => {
    if (qrData.length !== 85)
        throw new Error(`Failed to decode QR. Length must be 85. Got: ${qrData.length}.`);
    const version: number = qrData.slice(0, 1)[0];
    if (version !== 1)
        throw new Error(`Only version 1 is supported. Got: ${version}.`);
    return ({
        version: version,
        privateKey: Buffer.from(qrData.slice(1, 33)),
        contractAddress: '0x' + Buffer.from(qrData.slice(33, 53)).toString('hex'),
        chainId: '0x' + Buffer.from(qrData.slice(53, 85)).toString('hex'),
    }) as PrivateQrData;
};
